import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Badge from 'shopper/components/Badge';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useSessionStorageState from 'hooks/useSessionStorageState';

import { sendEvent } from 'lib/gtag';
import { toLandingPageLink } from 'lib/links';
import { getFeaturedLandingVisitedKey } from 'lib/utils';

const gaEventAction = 'featured_landing_tab';

const FeaturedLandingLink = ({
  gaEventCategory,
  landingName,
  landingSlug,
  landingType,
  position,
}) => {
  const [isFeaturedLandingBeenVisited, setFeaturedLandingBeenVisited] =
    useSessionStorageState(getFeaturedLandingVisitedKey(position), false);

  return (
    <NextLink
      href={toLandingPageLink(landingSlug, landingType)}
      prefetch={false}
      passHref
    >
      <Button
        as="a"
        className="relative ml-1 mr-2 hidden lg:flex"
        data-test-selector="featured-landing-tab"
        iconLeft={
          landingSlug === 'black-friday' ? (
            <Icon name="promobit-filled" />
          ) : null
        }
        size="size4"
        title={landingName}
        type="neutral-ghost"
        onClick={() => {
          setFeaturedLandingBeenVisited(true);
          sendEvent({
            action: gaEventAction,
            category: gaEventCategory,
          });
        }}
      >
        {landingName}
        {!isFeaturedLandingBeenVisited && (
          <Badge
            className="absolute right-0 top-1"
            shape="dot"
            type="highlight"
          />
        )}
      </Button>
    </NextLink>
  );
};

FeaturedLandingLink.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  landingName: PropTypes.string.isRequired,
  landingSlug: PropTypes.string.isRequired,
  landingType: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};

export default FeaturedLandingLink;
